import { Injectable, inject } from '@angular/core';
import { MessageType } from '@enums/message-type.enum';
import { MessageConfigType } from '@interfaces/message-config-type.interface';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';

/**
 * The length of notification visibility in milliseconds
 */
const DEFAULT_MESSAGE_LIFE_SPAN = 5000;

/**
 * Service for managing system notifications
 */
@Injectable({ providedIn: 'root' })
export class NotificationsService {
  private readonly messageService = inject(MessageService);
  private readonly translateService = inject(TranslateService);

  /**
   * Display notification success message
   */
  success(message: string, translation: boolean = true): void {
    this.messageService.add(this.getConfigType(MessageType.Success, message, translation));
  }

  /**
   * Display notification info message
   */
  info(message: string, translation: boolean = true): void {
    this.messageService.add(this.getConfigType(MessageType.Info, message, translation));
  }

  /**
   * Display notification warning message
   */
  warning(message: string, translation: boolean = true): void {
    this.messageService.add(this.getConfigType(MessageType.Warn, message, translation));
  }

  /**
   * Display notification error message
   */
  error(message: string, translation: boolean = true): void {
    this.messageService.add(this.getConfigType(MessageType.Error, message, translation));
  }

  /**
   * Get notification config depends on message type
   */
  private getConfigType(type: MessageType, message: string, translation: boolean): MessageConfigType {
    return {
      severity: type,
      detail: translation ? this.translateService.instant(message) : message,
      life: DEFAULT_MESSAGE_LIFE_SPAN,
    };
  }
}
